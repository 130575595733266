<template>
  <div class="bac">
    <div>
      <el-card shadow="always" style="width: 1500px">
       <el-button type="primary" @click="this.$router.push({path:'/AddProject',query: {companyId: subform.sid}})">添加项目</el-button>
        <el-table
            :data="info"
            highlight-current-row
            max-height="900"
            :header-cell-style="{'text-align':'center'}"
            :cell-style="{'text-align':'center'}">
          <el-table-column label="封面">
            <template #default="scope">
              <el-image :src="scope.row.pic" style="width: 156px;"></el-image>
            </template>
          </el-table-column>
          <el-table-column label="项目名称" prop="projectName"></el-table-column>
          <el-table-column label="公司名称" prop="companyName"></el-table-column>
          <el-table-column label="一级分类" prop="typeFirst"></el-table-column>
          <el-table-column label="二级分类" prop="typeSecond"></el-table-column>
          <el-table-column label="操作" width="500">
            <template #default="scope">
              <el-button @click="this.$router.push({path:'/Introduce',query: {pid: scope.row.id}})">查看</el-button>
              <!--todo:修改项目信息-->
              <!--              <el-button @click="this.$router.push({path:'/AddProject',query: {pid: scope.row.id}})">编辑信息</el-button>-->
              <el-button type="danger" @click="Delete(scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "AllProject",
  data(){
    return{
      subform:{
        sid:'',
        currentPage:1,
        pageSize: 20,
      },

      deleteFrom:{
        pid:'',
      },

      info:[''],
      total: 0,

    }
  },
  mounted() {
    this.subform.sid = this.$route.query.companyId;
    this.getInf()
  },
  methods:{
    getInf() {
      this.$http({
        method: 'post',
        url: '/getAllProjectsForSupplier',
        data: this.subform,
      }).then(res => {
        this.info = res.data.data;
        this.total = res.data.count;
        this.subform.currentPage = res.data.currentPage;
      })
    },
    Delete(index){
      this.deleteFrom.pid = index;
      this.$http({
        method: 'post',
        url: '/admin/deleteProjectOnlyAdmin',
        data: this.deleteFrom,
      }).then(res => {
        if (res.data.code === 1){
          this.$notify({
            title: '删除成功',
            message: res.data.message,
            type: 'success'
          });
          this.getInf();
        }
      })
    },
  }
}
</script>

<style scoped>
.bac{
  height: 1194px;
}
</style>